<template>
  <div>
    <b-row>
      <b-col class="mt-2"
        ><h3>{{ fragetext }}</h3></b-col
      >
    </b-row>
    <b-row v-if="hinweistext">
      <b-col class="mb-2">{{ hinweistext }}</b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="(opt, index) in optionen"
        :key="index"
        :xs="xs"
        :sm="sm"
        :md="md"
        :lg="lg"
        :xl="xl"
      >
        <b-form-radio
          v-model="selected"
          button
          button-variant="outline-dark"
          :name="`Frage_${Frage}`"
          :value="opt.value ? opt.value : opt.text"
          class="mx-1 my-1"
          >{{ opt.text }}</b-form-radio
        >
      </b-col>
    </b-row>
    <div v-if="showDiv">
      <b-row>
          <b-col>Welche Krankheit liegt vor und welche regelmäßigen Medikamente nimmst du ein?</b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-textarea
            v-model="antwort_3"
            placeholder=""
            rows="2"
            max-rows="4"
            @change="handleChange"
          ></b-form-textarea>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fragetext: "Nimmst du regelmäßig Medikamente ein oder leidest du zum Beispiel an einer der folgenden Krankheiten: Diabetes, Herzkrank, Autoimmunkrankheit",
      hinweistext: undefined,
      optionen: [
        { text: "Nein", show_2: false },
        { text: "Ja", show_2: true },
      ],
      selected: undefined,
      showDiv: false,
      antwort_3: "",
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      xl: 6,
      key: Math.random(),
    };
  },
  props: {
    Frage: { required: true },
  },
  watch: {
    selected() {
      this.handleChange();
    },
  },
  methods: {
    handleChange() {
      const curSel = this.optionen.filter((v) => v.text === this.selected);
      if (curSel.length > 0) {
        this.showDiv = curSel[0].show_2;
        if (!this.showDiv) {
            this.antwort_3 = ""
        }
      }

      this.$store.commit("setAnwser", {
        Frage: this.Frage,
        Fragentext: this.fragetext,
        Antwort: this.selected,
        Antwort_2: undefined,
        Antwort_3: this.antwort_3
      });
    },
  },
  beforeMount() {
    const answ = this.$store.getters.getAnwsers;
    if (answ[this.Frage - 1]) {
      this.selected = answ[this.Frage - 1].Antwort;
      this.antwort_3 = answ[this.Frage - 1].Antwort_3;
    }
    this.key = Math.random();
  },
};
</script>